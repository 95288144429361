











































.error-page {
  height: 100%;
  background: url('../assets/img/404-background.svg') no-repeat;
  background-size: cover;

  &-top-image {
    padding-top: 60px;
    text-align: right;

    img {
      width: 100%;
      max-width: 333px;
      height: 133px;
    }
  }

  &-bottom-image {
    margin-top: 85px;
    text-align: center;

    img {
      width: 257px;
      height: 291px;
    }
  }

  &-text {
    margin-top: 50px;
    text-align: center;
    padding: 10px 22px;

    h1 {
      font-size: 32px;
      font-family: "Raleway Bold", serif;
      margin-bottom: 12px;
      color: #2D2E2F;
    }

    p {
      font-size: 16px;
      line-height: 24px;
      font-family: "Raleway Regular", serif;
      color: #2D2E2F;
      margin: 0;
    }
  }

  &-back-btn {
    &-wrapper {
      padding: 8px 12px;
    }
  }
}
